import React from "react"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

const Termos = ({ data }) => {

  return (
    <StyledTermos>
      <p className={font.rSB + " title"} style={{ textTransform: "uppercase" }}>
        <span className="smallUnderline"></span>
        Termos e Condições
      </p>

      <p className={font.rR + ' txt'}>Adriano Carreira, Medicina Dentária, Lda., com sede na
        Rua José Afonso, Nº 2 e 4, Bloco B, Piso Zero 3800-438
        Aveiro, pessoa coletiva número 510949002, é detentora
        do presente website. A utilização do
        www.carreiradentalclinic.pt reconhece os termos e
        condições gerais abaixo enumerados e descritos. A sua
        aceitação é absolutamente indispensável à utilização dos
        serviços e informações aqui prestados. Caso não
        concorde com os mesmos, deverá cessar imediatamente
        a utilização deste website. www.carreiradentalclinic.pt é
        um serviço online com o objetivo de distribuir informação
        sobre os serviços prestados pela a Carreira Dental Clinic,
        bem como divulgar e promover as respetivas
        características e condições dos mesmos.
        À Carreira Dental Clinic reserva-se o direito de, a
        qualquer momento, sem necessidade de aviso prévio e
        com efeitos imediatos, alterar, adicionar, atualizar,
        eliminar, parcial ou totalmente, os presentes Termos e
        Condições de Utilização e Política de Privacidade.
        Garantindo, em qualquer circunstância, a qualidade,
        segurança, legalidade, veracidade e exatidão das
        informações dos respetivos serviços no website
        publicados. O utilizador deve consultar periodicamente a
        página destes Termos e condições de utilização e Política
        de Privacidade para confirmar se foram efetuadas
        quaisquer atualizações ou alterações. Caso não concorde
        com alguma destas não deve utilizar o presente Website.</p>
      <p className={font.rSB}>Utilização do Website </p>
      <p className={font.rR + ' txt'}>Os Utilizadores que visitam o Website podem aceder e ler
        todo o conteúdo contido no mesmo, sem necessidade de
        registar dados ou informações pessoais. No entanto,
        apenas os utilizadores registados ou com a submissão de
        formulários preenchidos para marcação de consulta serão
        contactados e informados sobre o serviço através do
        endereço eletrónico ou telefone previamente submetidos
        em formulários próprios. Receberão atualizações e
        informações sobre os serviços disponíveis na Carreira
        Dental Clinic, no caso de terem previamente aceite no
        respetivo registo ou formulário submetido, comunicação
        para esse efeito. </p>
      <p className={font.rSB}>Este Website, ou qualquer um dos nossos serviços, não
        deverá ser utilizado: </p>

      <p className={font.rR + ' txt'}><span className={font.rB}>• </span> Por qualquer razão ou finalidade que seja ilegal,
        difamatória, ofensiva, prejudicial, abusiva, invasiva da
        privacidade de outrem ou censurável;
      </p>
      <p className={font.rR + ' txt'}><span className={font.rB}>• </span> De forma que possa prejudicar ou interromper este
        Website ou qualquer um dos nossos serviços, ou a
        utilização pacífica por parte de outros utilizadores;
      </p>
      <p className={font.rR + ' txt'}><span className={font.rB}>• </span> Para transmitir vírus ou outra codificação maliciosa
        que possam danificar ou perturbar equipamento ou
        sistema informático (hardware ou software) ou
        equipamento de telecomunicações;
      </p>
      <p className={font.rR + ' txt'}><span className={font.rB}>• </span> De forma que possa ofender outrem ou prejudicar a
        Carreira Dental Clinic, designadamente, enviar ou
        disponibilizar qualquer conteúdo ilegal, falso,
        enganoso, ameaçador, maldoso, abusivo,
        difamatório, injurioso, invasivo da privacidade,
        prejudicial ou ainda que possa afetar negativamente
        a imagem ou atividade da Carreira Dental Clinic;
      </p>
      <p className={font.rR + ' txt'}><span className={font.rB}>• </span> Para promover qualquer tipo de atividade comercial, ou
        enviar ou disponibilizar informação ou conteúdos que
        pertencem a terceiros e que não tem o direito de utilizar,
        como, por exemplo, conteúdos protegidos por direitos de
        autor de terceiros ou conteúdos que contenham dados
        pessoais de terceiros.
      </p>

      <p className={font.rR + ' txt'}>Em geral, o Utilizador deve utilizar o Website de forma
        responsável, prudente e cuidadosa, não devendo
        perturbar ou danificar a continuidade ou integridade do
        mesmo. <br />
        A Carreira Dental Clinic não é responsável por quaisquer
        danos diretos, indiretos e ou negligentes decorrente do
        uso da informação contida neste Website ou do próprio
        Website bem como da conduta prejudicial ou
        perturbadora do Utilizador.
      </p>
      <p className={font.rSB}>Informações e conteúdos deste website
      </p>
      <p className={font.rR + ' txt'}>Todos os conteúdos e informações publicados no
        presente website são da responsabilidade da Carreira
        Dental Clinic que zela pela veracidade e transparência
        dos mesmos. Esforçando-se para oferecer informações
        úteis, atualizadas e ajustadas à realidade. No entanto, a
        mesma pode não estar correta ou completamente
        atualizada. Neste sentido, não nos podemos
        responsabilizar por quaisquer ações tomadas como
        resultado de ou com base nas informações contidas ou
        fornecidas por este Website, seja numa ação de contrato,
        negligência ou outra ação ilícita. Através do
        geral@carreiradentalclinic.pt poderá sempre enviar as
        suas sugestões ou erratas.
        <br />
        À Carreira Dental Clinic está reservado o direito de
        mover, alterar, excluir o Website, ou conteúdo do mesmo
        (incluindo o conteúdo de utilizadores registados, se o
        caso), a qualquer momento e por qualquer motivo. Deve,
        portanto, manter uma cópia de qualquer conteúdo que
        enviar para o Website, que deseje manter. Depois de ter
        carregado o conteúdo, pode optar por excluir o conteúdo
        a qualquer momento.
      </p>
      <p className={font.rR + ' txt'}>Caso deseje reportar as suas preocupações relativas a
        qualquer conteúdo disponível neste site, por favor, envie
        um e-mail diretamente para geral@carreiradentalclinic.pt
      </p>
      <p className={font.rSB}>Registo de dados
      </p>
      <p className={font.rR + ' txt'}>É permitido o registo de dados de todos os utilizadores
        interessados em receber informações ou marcar uma
        consulta ou serviço aqui anunciado e publicado.
      </p>
      <p className={font.rR + ' txt'}>O registo ou preenchimento de formulários são gratuitos.
        Para se registar como utilizador interessado em
        determinado serviço deve preencher o formulário e
        selecionar o pretendido: marcação ou pedido de
        informação.
        <br />
        Os dados pessoais de cada utilizador serão mantidos
        enquanto a subscrição e/ou pedidos do utilizador
        estiverem ativos, com o objetivo de melhorar os serviços
        oferecidos, pelo que após a sua caducidade serão
        despersonalizados e não identificáveis.
        <br />
        É-lhe permitido questionar os dados e poderá solicitar a
        eliminação dos dados, retificação, atualização ou
        completar os mesmos. Estes pedidos devem ser
        encaminhados para geral@carreiradentalclinic.pt
        Sugerimos a consulta da Política de Privacidade do
        presente Website.
      </p>
      <p className={font.rSB}>
        Utilização de Hyperlinks
      </p>
      <p className={font.rR + ' txt'}>
        O conteúdo deste Website pode incluir Hyperlinks
        (hiperligações) para outros Sites que não são operados
        pela Carreira Dental Clinic. A Carreira Dental Clinic não é
        responsável pelo conteúdo de qualquer outro Website,
        incluindo aqueles que dêem acesso ao presente ou cujo
        acesso tenha sido conseguido através do nosso Website.
      </p>
      <p className={font.rR + ' txt'}>
        A Carreira Dental Clinic não endossa quaisquer Sites
        vinculados ou os produtos/serviços que são fornecidos
        nesses Sites. Os conteúdos nos sites vinculados podem
        mudar ao longo do tempo. Recomenda-se a verificação
        dos termos e políticas dos Sites vinculados.

      </p>
      <p className={font.rR + ' txt'}>
        Ao fornecer neste Website um hyperlink para um Website
        externo, a Carreira Dental Clinic fá-lo na boa-fé de que
        este contenha ou possa conter informações relevantes
        adicionais às apresentadas no seu próprio Website. A
        existência desse hyperlink não implica nem pressupõe a
        sua revisão e aprovação por parte da Carreira Dental
        Clinic - em determinadas instâncias, um hyperlink poderá
        levá-lo a aceder a um Website que apresente informação
        contraditória à apresentada no nosso Website ou a outro
        pertencente à Carreira Dental Clinic ou às suas
        subsidiárias.
      </p>
      <p className={font.rSB}>
        A Nossa Responsabilidade e Contactos
      </p>
      <p className={font.rR + ' txt'}>
        A Carreira Dental Clinic não é responsável, por perdas ou
        danos diretos, indiretos ou específicos ou quaisquer
        outras consequências provenientes do acesso ou
        utilização deste Website, nem de qualquer informação no
        mesmo contida. Não obstante, estamos disponíveis para
        discutir com o utilizador as implicações de qualquer
        questão que venha a surgir, devendo contactar-nos
        através do nosso site em www.carreiradentalclinic.pt
        (contactos), através da morada Rua José Afonso, Nº 2 e
        4, Bloco B, Piso Zero 3800-438 Aveiro, do nosso telefone
        +351 234 423 042 / +351 220 925 530 / +351 243 384
        992 / +351 232 418 923 / +351 256 131 702, ou através
        do endereço de email geral@carreiradentalclinic.pt.
      </p>
      <p className={font.rR + ' txt'}>Ao aceder à nossa área de contacto, o utilizador estará a
        aceitar que a informação que nos presta, através desse
        contacto, passará a ser detida pela Carreira Dental Clinic,
        que a poderá utilizar nos termos que entender mais
        adequados, não havendo lugar, em situação alguma, a
        qualquer direito ou pagamento a seu favor por essa
        utilização.
        <br />
        Até ao limite máximo permitido por lei, todas as garantias,
        explícitas ou implícitas, encargos ou avais,
        representações e outros termos e condições de qualquer
        tipo relacionada com este Website ou matéria não contida
        nestes Termos e Condições estão expressamente
        excluídas. </p>
      <p className={font.rR + ' txt'}>Nada nestes Termos de Utilização exclui, restringe ou
        modifica qualquer direito ou recurso, ou quaisquer
        garantias, ou outro termo ou condição, implícita ou
        impostas por qualquer legislação que não possam ser
        legalmente excluídas ou limitadas. Isso pode incluir a
        legislação referente aos Direitos do Consumidor, em
        território Português, que contém garantias que protegem
        os Consumidores de bens e serviços em determinadas
        circunstâncias.
        <br />
        A responsabilidade de uma parte para com outra parte é
        reduzida na medida em que os atos ou omissões da outra
        parte, ou aqueles de uma terceira parte, contribuem para
        ou causam tal responsabilidade.
        <br />
        Apesar de desenvolvermos as correspondentes ações
        para manter este Website livre de vírus informáticos ou
        outra codificação danosa, não podemos garantir que este
        seja o caso. Deverá, portanto, utilizar um software de
        segurança, atualizado a todo o tempo, para proteger o
        seu computador de tais softwares maliciosos.
      </p>
      <p className={font.rR + ' txt'}>
        Apesar de desenvolvermos todos os esforços comerciais
        razoáveis para manter este Website disponível, não
        garantimos que o mesmo esteja acessível ou disponível
        numa base temporal constante ou que o acesso não seja
        interrompido ou isento de erros.
      </p>
      <p className={font.rR + ' txt'}>
        A Carreira Dental Clinic é a entidade responsável pela
        recolha e tratamento de dados pessoais. Respeita a
        privacidade de todos os utilizadores e compromete-se em
        proteger todas as informações submetidas por cada
        utilizador em conformidade com a legislação nacional e
        comunitária em vigor.
        <br />
        Todos os utilizadores podem navegar neste website sem
        a obrigatoriedade de efetuar ou submeter qualquer
        registo de dados. Quando submete os seus dados, caso
        pretenda receber novidades, campanhas ou outros
        associados aos nossos serviços pedimos que concorde
        com o envio da mesma.
        <br />
        O fornecimento de dados pessoais apenas é necessário
        no caso de pretender efetuar uma marcação de consulta,
        um pedido de informações ou pedido de contacto e será
        sempre garantido, nos termos da lei, o direito de acesso,
        retificação e anulação de qualquer dado fornecido,
        podendo aquele direito ser exercido por escrito, a
        qualquer momento, através do e-mail
        geral@carreiradentalclinic.pt ou clicando no link de
        cessação da subscrição, na parte inferior do e-mail.
      </p>
      <p className={font.rSB}>Registo de dados</p>
      <p className={font.rR + ' txt'}>
        Recolhemos informação pessoal, via o preenchimento
        voluntário dos devidos formulários dispostos para
        marcação de consultas, pedidos de informações ou
        serviços e pedidos de contacto.
        <br />
        Ao contactar-nos através de e-mail, subscrever
        informações grátis, obriga-nos à recolha do seu endereço
        de e-mail, nome e outros dados específicos de
        identificação pessoal, traduzidos normalmente pelo nome
        e número de contacto.
      </p>
      <p className={font.rR + ' txt'}>
        Ao pedir dados pessoais sensíveis, assegurar-nos-emos
        que a sua recolha e utilização será feita no rigoroso
        cumprimento da legislação aplicável à recolha e proteção
        de dados. Se pretendermos utilizar os seus dados
        pessoais para além dos fins estabelecidos inicialmente,
        pediremos o seu consentimento. Poderá escolher
        antecipadamente dar esse consentimento aquando da
        recolha inicial de dados.
      </p>
      <p className={font.rSB}>Dados recolhidos automaticamente</p>
      <p className={font.rR + ' txt'}>Os domínios e endereços de IP dos visitantes são
        registados automaticamente. Esta informação não
        identifica o utilizador, mas apenas o computador utilizado
        no acesso ao site. Estes dados são analisados a nível
        global para verificar em que local do mundo é que o site
        está a ser utilizado, de forma a assegurar a sua
        cobertura, permitindo que o nosso serviço ao utilizador
        possa ser melhorado. Os dados pessoais de cada
        utilizador não são objeto de recolha e análise neste
        processo. </p>
      <p className={font.rSB}>Retenção de dados</p>
      <p className={font.rR + ' txt'}>Os dados serão mantidos enquanto a subscrição do
        utilizador estiver ativa, com o objetivo de melhorar os
        serviços oferecidos, pelo que após a sua caducidade
        serão despersonalizados e não identificáveis. Apenas
        serão recolhidas informações adicionais acerca do
        utilizador para além das mencionadas acima caso surja
        necessidade de se obter mais informações clínicas. </p>
      <p className={font.rSB}>Acesso aos dados pessoais</p>
      <p className={font.rR + ' txt'}>Para solicitar esclarecimentos acerca do acesso aos
        dados pessoais, ou para o esclarecimento de questões
        relacionadas com a política de privacidade de dados,
        praticada na Carreira Dental Clinic, contacte-nos através
        do e-mail geral@carreiradentalclinic.pt, ou utilize o
        seguinte endereço postal: Rua José Afonso, Nº 2 e 4,
        Bloco B, Piso Zero 3800-438 Aveiro. Fornecer-lhe-emos
        uma cópia dos dados pessoais que dispomos sobre si
        num prazo máximo de 40 dias, a contar do seu pedido
        feito para este site; para este fim, ser-lhe-á exigida uma
        prova de identificação. É-lhe permitido questionar os
        dados e, onde apropriado, poderá: apagar os dados,
        retificá-los, emendá-los ou completá-los. Reservamo-nos
        ao direito de recusa de fornecimento da cópia dos dados
        pessoais do utilizador, obrigando-nos a apresentar uma
        justificação para essa decisão. O utilizador poderá
        contestar a nossa decisão de recusa de fornecimento de
        cópia com os seus dados pessoais.  </p>
      <p className={font.rSB}>Revelação e escolha do visitante</p>
      <p className={font.rR + ' txt'}>O utilizador poderá cessar a subscrição do seu registo a
        qualquer momento, sendo este um processo simples. Ao
        disponibilizar os seus dados pessoais no início,
        fornecemos-lhe os procedimentos para o seu
        cancelamento, quer através de um link, quer através da
        disponibilização de um endereço de e- mail
        geral@carreiradentalclinic.pt. Ao disponibilizarmos os
        dados pessoais do utilizador para propósitos diferentes
        dos indicados, oferecemos a oportunidade ao utilizador
        de dar a sua autorização prévia.  </p>
      <p className={font.rSB}>Segurança</p>
      <p className={font.rR + ' txt'}>Foram implementadas políticas de segurança, regras e
        medidas técnicas para proteger os dados pessoais sob o
        nosso controlo, que estão inteiramente de acordo com a
        legislação de proteção de dados, apropriada à jurisdição
        aplicável ao site. As medidas de segurança estão
        desenhadas para prevenir acessos não autorizados,
        utilizações impróprias, modificações não autorizadas e
        destruições ou perdas acidentais ilícitas.   </p>
      <p className={font.rSB}>Cumprimento/conformidade de privacidade</p>
      <p className={font.rR + ' txt'}>A nossa política de privacidade está de acordo com a
        legislação portuguesa aplicável, nomeadamente a Lei n.o
        67/98, de 26/10. A legislação de proteção de dados está
        sujeita a revisão, pelo que recomendamos ao utilizador a
        consulta regular da nossa declaração de privacidade. A
        título meramente indicativo, poderá para este efeito
        consultar o site carreiradentalclinic.pt. Este website
        encontra-se em conformidade com a legislação nacional
        e comunitária. Os tribunais portugueses terão jurisdição
        exclusiva sobre qualquer disputa proveniente da
        utilização deste website.    </p>
      <p className={font.rSB}>Hyperlinks</p>
      <p className={font.rR + ' txt'}>Os websites da Carreira Dental Clinic poderão fornecer
        links para sites externos para sua conveniência e
        informação. Ao aceder a esses links sairá dos sites da
        Carreira Dental Clinic, pelo que, a Carreira Dental Clinic
        não controla esses websites nem as suas políticas de
        privacidade, que poderão ser diferentes das da Carreira
        Dental Clinic;    </p>
      <p className={font.rSB}>Não aprova nem representa estes sites.</p>
      <p className={font.rR + ' txt'}>A Declaração de Privacidade da Carreira Dental Clinic
        não cobre os dados pessoais eventualmente cedidos pelo
        utilizador para sites externos à Carreira Dental Clinic.
        Recomendamos-lhe que reveja as políticas de
        privacidade de qualquer companhia antes de submeter a
        sua informação pessoal. Algumas companhias externas
        poderão escolher partilhar a sua informação pessoal com
        a Carreira Dental Clinic, pelo que esta partilha será regida
        pela política de privacidade dessa companhia.</p>
    </StyledTermos>
  )
}

export default Termos

const StyledTermos = styled.div`
  position: relative;
  padding: 0 25% 5em;
  .title{
    font-size:calc(22px + 4 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px){
      font-size: 26px;
    }
    letter-spacing: 0.1em;
    color: #231f20;
    position: relative;
    margin-bottom: 2em;
    .smallUnderline{
      width: 2em;
      height: 0.25em;
      background-color: #b7a99a;
      position: absolute;
      top: 1.6em;
      left: 0;
    }
  }
  p{
    font-size: calc(12px + 12 * (100vw - 300px) / 1620);
    line-height: calc(22px + 11 * (100vw - 300px) / 1620);
    margin: 2em 0;
    color: #707070;
  }
  .txt{
    font-size: calc(12px + 10 * (100vw - 769px) / 1151);
      @media only screen and (min-width: 1920px){
        font-size: 22px;
      }
      letter-spacing: 0.1em;
      line-height: 1.6em;
      color: #808080;
      white-space: pre-wrap;
  }
  @media only screen and (max-width: 1200px){
    padding: 0 18% 5em;
  }
  @media only screen and (max-width: 696px){
    .title{
      .smallUnderline{
        top: 3em;
      }
    }
  }
  @media only screen and (max-width: 500px){
    .title{
      .smallUnderline{
        top: 3.5em;
      }
    }
  } 
`